import { useBackendApi } from '@/api';
import { Task } from '@/api/schemas';
import { StyledFlexNowrapGap10 } from '@/components/styled';
import { TASK_TYPE_TO_EN_MODE_FROM_BACKEND } from '@/constants';
import { BatchDownloadDialog } from '@/features/components/modals/BatchDownloadDialog';
import { PopupDownloadFormat } from '@/features/components/modeless/PopupDownloadFormat';
import { useDialog } from '@/hooks/local/useDialog';
import { usePopper } from '@/hooks/local/usePopper';
import { IconButton } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { MdCreate, MdDownload, MdViewModule } from 'react-icons/md';

type Props = {
  data: Task;
  handleSubmitTargetDownloadStep: (id: string) => void;
  handleOpenDownloadDialog: (id: string) => void;
};

export const DownloadButton = memo(
  ({
    data,
    handleSubmitTargetDownloadStep,
    handleOpenDownloadDialog,
  }: Props): JSX.Element => {
    const { getTask } = useBackendApi({});
    const [taskApiResponse, setTaskApiResponse] = useState<Task | undefined>(
      undefined,
    );
    const [targetModeEn, setTargetModeEn] = useState<string>('');
    const { isOpenDialog, handleOpenDialog, handleCloseDialog } = useDialog();
    const { isOpenPopper, handleOpenPopper, handleClosePopper, anchorElement } =
      usePopper();
    const handleOpenDownloadPopper = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        handleOpenPopper(e);
      },
      [handleOpenPopper],
    );

    const handleOpenBatchDownloadDialog = useCallback(
      (modeEn: string) => {
        void (async () => {
          try {
            setTaskApiResponse(await getTask(data.id, {}));
            setTargetModeEn(modeEn);
            handleOpenDialog();
          } catch (error) {
            console.error(error);
          }
        })();
      },
      [data.id, getTask, handleOpenDialog],
    );

    const handleCloseBatchDownloadDialog = useCallback(() => {
      handleCloseDialog();
      setTaskApiResponse(undefined);
    }, [handleCloseDialog]);

    const modeEn =
      TASK_TYPE_TO_EN_MODE_FROM_BACKEND[
        data.taskType as keyof typeof TASK_TYPE_TO_EN_MODE_FROM_BACKEND
      ];
    console.log(modeEn);

    return (
      <>
        {data.result.status === 'COMPLETED' && (
          <StyledFlexNowrapGap10>
            {modeEn === 'colorTemperature' || modeEn === 'backgroundLora' ? (
              <>
                <IconButton
                  style={{ color: 'var(--color-primary-main)' }}
                  onClick={() => {
                    handleOpenBatchDownloadDialog(modeEn);
                  }}
                >
                  <MdViewModule size={24} />
                </IconButton>
                <IconButton
                  sx={{ color: 'var(--color-primary-main)' }}
                  onClick={(e) => handleOpenDownloadPopper(e)}
                >
                  <MdDownload />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  sx={{ color: 'var(--color-primary-main)' }}
                  onClick={() => {
                    handleSubmitTargetDownloadStep(data.id);
                  }}
                >
                  <MdCreate size={22} />
                </IconButton>
                <IconButton
                  sx={{ color: 'var(--color-primary-main)' }}
                  onClick={() => {
                    handleOpenDownloadDialog(data.id);
                  }}
                >
                  <MdDownload />
                </IconButton>
              </>
            )}
          </StyledFlexNowrapGap10>
        )}
        {isOpenDialog && taskApiResponse && (
          <BatchDownloadDialog
            isOpenDialog={isOpenDialog}
            handleCloseDialog={handleCloseBatchDownloadDialog}
            taskApiResponse={taskApiResponse}
            targetModeEn={targetModeEn}
          />
        )}
        {isOpenPopper && (
          <PopupDownloadFormat
            popperTitle="出力形式の選択"
            popperPlacement="bottom"
            anchorElement={anchorElement}
            isOpenPopper={isOpenPopper}
            handleClosePopper={handleClosePopper}
            taskApiResponse={data}
            // App482にて背景Loraのpsd出力をオフにするための暫定対応
            // hasPsd={modeEn === 'backgroundLora'}
            hasPsd={false}
          />
        )}
      </>
    );
  },
);
