import {
  StyledBoxHeight10,
  StyledFont,
  StyledFontBold,
  StyledGridAlignStart,
} from '@/components/styled';
import { StylePopperUserInfoButton } from '@/components/styled/button';
import { memo } from 'react';
import { StyledPopperFlex, StyledPopperGrid } from '../../styled';
import { useUserInfoPopper } from '../hooks/useUserInfoPopper';

/**
 * JSDoc
 * @see ユーザー情報ポッパー
 * @returns {JSX.Element}
 */
type UserInfoPopperProps = {
  handleOpenPasswordResetDialog: () => void;
  handleCloseThisPopper: () => void;
}

export const UserInfoPopper = memo(({
  handleOpenPasswordResetDialog,
  handleCloseThisPopper,
}: UserInfoPopperProps): JSX.Element => {
  const {
    user,
    handleResetPassword,
    handleLogout,
  } = useUserInfoPopper();

  return (
    <>
      <StyledPopperGrid>
        <StyledPopperFlex>
          <StyledGridAlignStart>
            <StyledFontBold>
              {user && user.email && <>{user.email}</>}
            </StyledFontBold>
            <StyledFont>
              {user && user.organization && (
                <>{user.organization?.displayName}</>
              )}
            </StyledFont>
            <StyledBoxHeight10 />
          </StyledGridAlignStart>
        </StyledPopperFlex>
        <StylePopperUserInfoButton onClick={() => {
          handleResetPassword()
          handleOpenPasswordResetDialog();
          handleCloseThisPopper();
        }}>
          パスワードを再設定
        </StylePopperUserInfoButton>
        <StylePopperUserInfoButton onClick={handleLogout}>
          ログアウト
        </StylePopperUserInfoButton>
      </StyledPopperGrid>
    </>
  );
});
