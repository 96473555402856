import { Task } from '@/api/schemas';
import houndstooth from '@/assets/any/houndstooth.png';
import { Box } from '@/components/atoms/Box';
import { Button } from '@/components/atoms/Button';
import { BackgroundIcon } from '@/components/icons';
import {
  StyledBoxPaddingTop100,
  StyledFlex,
  StyledFlexEnd,
} from '@/components/styled';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { PopupDownloadFormat } from '@/features/components/modeless/PopupDownloadFormat';
import { GlobalDownloader } from '@/features/components/organisms/GlobalDownloader';
import { StyledFunctionBox } from '@/features/components/styled/function';
import { StyledSmallIconScale } from '@/features/components/styled/icon';
import { FeaturesModalDownloadLayout } from '@/features/components/templates/Layout/FeaturesModalDownloadLayout';
import { useDownload } from '@/hooks/global/useDownload';
import { usePopper } from '@/hooks/local/usePopper';
import { useConvertDate } from '@/hooks/utils/useConvertDate';
import { Divider } from '@mui/material';
import Image from 'next/image';
import { memo, useCallback } from 'react';
import {
  StyledDialogContainer,
  StyledDialogWidthBox,
  StyledHeaderBox,
} from '../styled';

type Props = {
  taskApiResponse: Task;
};

export const BackgroundLoraContent = memo(
  ({ taskApiResponse }: Props): JSX.Element => {
    console.log('batch download dialog');
    const { convertToDateTime } = useConvertDate();
    const { setFiles } = useDownload({
      taskApiResponse,
    });
    const { isOpenPopper, handleOpenPopper, handleClosePopper, anchorElement } =
      usePopper();

    const handleOpenDownloadPopper = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        handleOpenPopper(e);
      },
      [handleOpenPopper],
    );

    return (
      <>
        <StyledDialogContainer>
          <FeaturesModalDownloadLayout>
            <StyledDialogWidthBox>
              <StyledHeaderBox>
                <StyledFlex style={{ minWidth: 320, gap: '10px' }}>
                  <Box
                    style={{
                      position: 'relative',
                      width: 56,
                      height: 56,
                      minWidth: 56,
                    }}
                  >
                    {taskApiResponse.result &&
                      taskApiResponse.result.resultImages[0] &&
                      taskApiResponse.result.resultImages[0].url && (
                        <ImageSpinner
                          src={`${taskApiResponse?.result.resultImages[0].url}&d=56x56`}
                          hasBackground={false}
                        >
                          <Image
                            src={`${taskApiResponse?.result.resultImages[0].url}&d=56x56`}
                            alt="result image"
                            fill
                            quality={90}
                            style={{
                              objectFit: 'contain',
                              objectPosition: 'center',
                              borderRadius: '4px',
                              border: '1px solid #ccc',
                            }}
                          />
                        </ImageSpinner>
                      )}
                  </Box>
                  <Box>
                    <StyledFlex
                      style={{
                        whiteSpace: 'nowrap',
                        alignItems: 'center',
                      }}
                    >
                      <Box style={{ height: 24 }}>
                        <StyledSmallIconScale>
                          <BackgroundIcon color="#424242" />
                        </StyledSmallIconScale>
                      </Box>
                      <Box style={{ height: 24, fontSize: 14 }}>
                        背景（複数枚）
                      </Box>
                    </StyledFlex>
                    {taskApiResponse?.displayName}
                  </Box>
                </StyledFlex>

                <StyledFlex style={{ minWidth: 240, gap: '10px' }}>
                  <Box>
                    <Box style={{ height: 24 }}>
                      {convertToDateTime(taskApiResponse?.updatedAt)}
                    </Box>
                    <Box>{taskApiResponse.user.email}</Box>
                  </Box>
                </StyledFlex>

                <StyledFlexEnd>
                  <Box style={{ height: 24, fontSize: 14 }}>生成完了</Box>
                </StyledFlexEnd>
              </StyledHeaderBox>
              <Divider />
            </StyledDialogWidthBox>
            <StyledBoxPaddingTop100>
              <StyledFlex
                style={{
                  position: 'relative',
                  alignItems: 'flex-start',
                  width: '1100px',
                  gap: '20px',
                }}
              >
                <GlobalDownloader
                  isGenerated
                  imageResults={taskApiResponse?.result.resultImages || []}
                  setFiles={setFiles}
                  repeatBackgroundImage={
                    taskApiResponse.parameters?.noBackground
                      ? houndstooth.src
                      : ''
                  }
                  hasCheckIcon={false}
                  taskType={taskApiResponse.taskType}
                />
              </StyledFlex>
            </StyledBoxPaddingTop100>
            <StyledFunctionBox>
              <StyledBoxPaddingTop100>
                <Box style={{ position: 'absolute', bottom: 0, left: 20 }}>
                  <Button
                    style={{ width: 260 }}
                    onClick={handleOpenDownloadPopper}
                  >
                    ダウンロード
                  </Button>
                </Box>
              </StyledBoxPaddingTop100>
            </StyledFunctionBox>
          </FeaturesModalDownloadLayout>
        </StyledDialogContainer>

        <PopupDownloadFormat
          popperTitle="出力形式の選択"
          popperPlacement="bottom"
          anchorElement={anchorElement}
          isOpenPopper={isOpenPopper}
          handleClosePopper={handleClosePopper}
          taskApiResponse={taskApiResponse}
          /* モーダルにポップアップを表示すると影が分かりづらいため濃くする */
          boxShadow="0px 0px 20px 0px rgba(0,0,0,0.5)"
          // App482にて背景Loraのpsd出力をオフにするための暫定対応
          hasPsd={false}
        />
      </>
    );
  },
);
